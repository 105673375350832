@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/************ Custom Font ************/
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: Centra;
  src: url("./assets/font/CentraNo2-Book.ttf");
  font-weight: 400;
}

/************ Default Css ************/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 2px 2;
  background-color: #121212;
}
nav.navbar.not_scrolled {
  background-color: #12121285;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
  margin-top: 6px;
  margin-bottom: 6px;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}
span.navbar-text {
  display: flex;
  align-items: center;
}
.social-icon {
  display: inline-block;
  margin-left: 14px;
}
.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 6px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button {
  font-weight: 700;
  color: #fff;
  border: 1px solid #fff;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #121212;
}
.navbar-text button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 24px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

.about-us {
  /* background: #000; */
  height: 100%;
  width: 100%;
  background-image: url("./assets/img/about.png");
}
/************ Banner Css ************/
.overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 1; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.banner {
  margin-top: 0;
  padding: 300px 0 300px 0;
  background-image: url("./assets/img/Banner1.jpg");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  font-size: 65px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.title {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 54px;
  text-align: center;
}

.sub_title {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  text-align: center;
  margin-bottom: 24px;
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  /* padding: 0 0 0px 0; */
  position: relative;
  background-color: rgb(238, 238, 238);
}
.skill_title {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 38px;
  text-align: center;
  color: #000;
}
.skill-bx {
  /* background: #151515;
  border-radius: 64px; */
  text-align: center;
  /* padding: 30px 20px; */
  margin-top: 60px;
}
.skill h2 {
  font-size: 45px;
  font-weight: 700;
}
.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 60px 0;
}
.skill-slider {
  width: 95%;
  margin: 0 auto;
  position: relative;
}
/* .skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
} */

.item {
  /* background-image: url('./assets/img/cad.png'); */
  height: 250px;
  width: 350px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 42px;
  position: relative;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

.skill-imgbx {
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 60px;
}

.skill-imgbx::before {
  content: "";
  /* background: linear-gradient(90.21deg, #2d2d2de1 -5.91%, #dadadad5 111.58%); */
  /* background: #00000050; */
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}

.skill-imgbx:hover::before {
  height: 100%;
}
.skill-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
/************ Projects Css ************/
.project {
  padding: 80px 0;
  position: relative;
  background-color: rgb(238, 238, 238);
}
.project h2 {
  font-size: 45px;
  font-weight: 700;
  text-align: center;
}
.project p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px auto 30px auto;
  text-align: center;
  width: 56%;
}
.project .nav.nav-pills {
  width: 72%;
  margin: 0 auto;
  border-radius: 50px;
  background-color: rgb(255 255 255 / 10%);
  overflow: hidden;
}
.project .nav.nav-pills .nav-item {
  width: 33.33333%;
}
.project .nav.nav-pills .nav-link {
  background-color: transparent;
  border-radius: 0;
  padding: 17px 0;
  color: #fff;
  width: 100%;
  font-size: 17px;
  letter-spacing: 0.8px;
  font-weight: 500;
  position: relative;
  transition: 0.3s ease-in-out;
  text-align: center;
  z-index: 0;
}
.project .nav.nav-pills .nav-link::before {
  content: "";
  position: absolute;
  width: 0;
  height: 100%;
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  top: 0;
  left: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.project .nav.nav-pills .nav-link.active::before {
  width: 100% !important;
}
.project .nav.nav-pills .nav-link.active {
  border: 1px solid rgba(255, 255, 255, 1);
}
.nav-link#projects-tabs-tab-first {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 55px 0px 0px 55px;
}
.nav-link#projects-tabs-tab-second {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.nav-link#projects-tabs-tab-third {
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 0 55px 55px 0;
}
.proj-imgbx {
  position: relative;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 24px;
}
.proj-imgbx::before {
  content: "";
  background: linear-gradient(90.21deg, #2d2d2de1 -5.91%, #dadadad5 111.58%);
  opacity: 0.85;
  position: absolute;
  width: 100%;
  height: 0;
  transition: 0.4s ease-in-out;
}
.proj-imgbx:hover::before {
  height: 100%;
}
.proj-txtx {
  position: absolute;
  text-align: center;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 0.5s ease-in-out;
  opacity: 0;
  width: 100%;
}
.proj-imgbx:hover .proj-txtx {
  top: 50%;
  opacity: 1;
}
.proj-txtx h4 {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1.1em;
}
.proj-txtx span {
  font-style: italic;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: 0.8px;
}
.background-image-right {
  top: 20%;
  position: absolute;
  bottom: 0;
  width: 35%;
  right: 0;
  z-index: -4;
}

.flexContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  color: rgb(46, 46, 46);
}
.flexBox {
  margin-left: 32px;
  margin-right: 32px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;

  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 24px;
  text-align: center;
  color: #000;
}

/************ data count **************/

.data_count {
  background-image: url("./assets/img/dash.png");
  padding: 16px;
}
.data_countbx {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 100px;
}
.count_tx {
  font-family: "Roboto", sans-serif;
  font-weight: 1000;
  font-style: normal;
  font-size: 28px;
  text-align: center;
  color: #e6e6e6;
  margin-top: 16px;
  align-self: flex-start;
  margin-left: 8px;
}
.count_titx {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  text-align: left;
  color: #e6e6e6;
  align-self: flex-start;
  margin-left: 8px;
}

/************ choose us **************/

.our_box {
  padding: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 18px;
  text-align: center;
  color: #e6e6e6;
}

.desc_txt {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
  font-size: 16px;
  text-align: center;
  color: #e6e6e6;
}

/************ Projects Css ************/
/* .contact {
  background: url("./assets/img/contact.png");
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  background-size: cover;
  color: #000;
}
.contact img {
  width: 92%;
}
.contact h2 {
	font-size: 45px;
	font-weight: 700;
  margin-bottom: 30px;
}
.contact form input, .contact form textarea {
  width: 100%;
  background: rgb(255, 255, 255);
  border: 0px solid rgba(24, 24, 24, 0.5);
  color: #000000;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus, .contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #000000;
}
.contact form input::placeholder, .contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #a1a1a1;
  font-weight: 500;
}
.contact form input:focus::placeholder, .contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}
.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
  border-radius: 28px;
}
.contact form button:hover::before {
  width: 100%;
  border-radius: 28px;
} */

/************ Footer Css ************/
.footer {
  padding: 0 0 50px 0;
  background-image: url("./assets/img/footer-bg.png");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.newsletter-bx {
  background: #ffffff;
  border-radius: 55px;
  color: #121212;
  padding: 85px 125px;
  margin-bottom: 80px;
  margin-top: -122px;
}
.newsletter-bx h3 {
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 1.2em;
}
.new-email-bx {
  background: #fff;
  padding: 5px;
  border-radius: 20px;
  position: relative;
  z-index: 0;
  display: flex;
  align-items: center;
}
.new-email-bx::before {
  content: "";
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: -1px;
  left: -1px;
  bottom: -1px;
  right: -1px;
}
.new-email-bx::after {
  content: "";
  background: #fff;
  border-radius: 20px;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.new-email-bx input {
  width: 100%;
  color: #121212;
  font-weight: 500;
  background: transparent;
  border: 0;
  padding: 0 15px;
}
.new-email-bx button {
  background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
  padding: 20px 65px;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.5px;
  border-radius: 18px;
}
.footer img {
  width: 26%;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
}

.footer_link {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: #2c2c2c;
  line-height: 28px;
}

.btn {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    90.21deg,
    rgba(170, 54, 124, 0.5) -5.91%,
    rgba(74, 47, 189, 0.5) 111.58%
  );
  border: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
  color: #fff;
}

/* 
.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
}
.each-slide-effect {
  position: relative;
}

.each-slide-effect span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
} */

.each-slide-effect {
  /* position: relative; */
  width: 100vw;
  height: 100vh;
}

.each-slide-effect div {
  /* position: relative; */
  width: 100vw;
  height: 100vh;
}

.overlay {
  position: absolute;
  display: block;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  ); /* Adjust the color and opacity as needed */
  z-index: 1; /* Ensure the overlay is above the background image */
}

.each-slide-effect span {
  position: relative;
  z-index: 2; /* Ensure the text is above the overlay */
  color: white;
  font-size: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

/* bulletens */
.custom-bullets {
  list-style-type: square; /* Change the bullet type to square */
  padding-left: 20px; /* Add some padding to indent the list */
}

.custom-bullets li {
  margin-bottom: 10px; /* Add some spacing between list items */
}

/* Example of using a custom image as bullet */
.custom-bullets {
  list-style: none; /* Remove default bullets */
}

.custom-bullets li {
  margin-bottom: 10px; /* Add some spacing between list items */
  position: relative; /* Position relative for custom bullet */
  padding-left: 20px; /* Add padding for custom bullet */
}

.custom-bullets li::before {
  content: ""; /* No text content */
  position: absolute; /* Absolute positioning */
  left: 0; /* Position on the left */
  top: 50%; /* Center vertically */
  transform: translateY(-50%); /* Center vertically */
  width: 10px; /* Custom bullet size */
  height: 10px; /* Custom bullet size */
  background-color: #000; /* Custom bullet color */
  border-radius: 50%; /* Make the bullet round */
}

/* contact us */

.contact {
  font-family: Arial, sans-serif;
  /* background-color: #f2f2f2; */
  background: url("./assets/img/contact_bg.png");
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  color: #000;
}

.contact_container {
  display: flex;
  /* background-color: white; */
  /* padding: 20px; */
  border-radius: 8px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  /* max-width: 800px; */
  width: 100%;
}

.contact-info {
  flex: 1;
  padding-right: 20px;
  border-right: 1px solid #ccc;
}

.contact-info h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.enquiry-section h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.enquiry-section p {
  margin: 5px 0;
}

.form-container {
  flex: 1;
  padding-left: 20px;
}

.form-container h2 {
  font-size: 18px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input[type="text"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group input[type="radio"] {
  margin-right: 10px;
}

.form-group label {
  margin-right: 20px;
}

textarea {
  height: 100px;
}

button {
  background-color: #333;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}
